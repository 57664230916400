import { withStatusAndMessage } from "./withStatusAndMessage";

const className = "session-button";

const sessionButtonSessionLoading = () =>
    withStatusAndMessage("sessionLoading")({
        className,
        disabled: true,
    });

const sessionButtonSessionReady = (invokeStartSession, isInvoking) =>
    withStatusAndMessage("sessionReady")({
        className,
        onClick: invokeStartSession,
        loading: isInvoking || false,
    });

const sessionButtonSessionActive = (confirmEndSessionAction, isInvoking) =>
    withStatusAndMessage("sessionRunning")({
        className,
        onClick: () => confirmEndSessionAction(true),
        loading: isInvoking || false,
    });

const sessionButtonSessionEnded = () =>
    withStatusAndMessage("sessionFinished")({
        className,
        disabled: true,
    });

export const SessionButton = ({
    invokeStartSession,
    confirmEndSessionAction,
    sessionReady,
    sessionLoading,
    sessionActive,
    sessionEnded,
    isInvoking,
}) => {
    if (sessionLoading) {
        return sessionButtonSessionLoading();
    } else if (sessionReady) {
        return sessionButtonSessionReady(invokeStartSession, isInvoking);
    } else if (sessionActive) {
        return sessionButtonSessionActive(confirmEndSessionAction, isInvoking);
    } else if (sessionEnded) {
        return sessionButtonSessionEnded();
    } else {
        return null;
    }
};
