import React, { useState, useRef } from "react";
import _get from "lodash/get";
import _pick from "lodash/pick";

import {
    LabHeader,
    LabContent,
    Sidebar,
    ConsoleSecrets,
    TableOfContents,
    SessionQuery
} from "..";
import { useActiveIndexScroller, useHashScrollOnLoad } from "../../utils/hooks";
import "./LabWrapper.scss";

const LabWrapper = ({ GetLab, GetBlueprint, GetMarkdown }) => {
    useHashScrollOnLoad(window);
    const [tocElements, setToc] = useState([]);
    const [isInvoking, setIsInvoking] = useState(false);
    const bodyRef = useRef(null);
    const contentRef = useRef(null);
    const tocIds = tocElements.map(toc => toc.anchor);
    const activeTocIndex = useActiveIndexScroller(tocIds, bodyRef, contentRef);

    const {
        labId,
        sessionReady,
        sessionLoading,
        sessionActive,
        sessionEnded,
        sessionError,
    } = GetLab.data;

    const commonBtnProps = {
        sessionReady,
        sessionLoading,
        sessionActive,
        sessionEnded,
    };

    const xhrErrorStatuses = [GetLab, GetBlueprint, GetMarkdown]
        .map(data => _get(data.error, "status"))
        .filter(status => status >= 0);

    return (
        <div className="LabPage">
            <SessionQuery labId={labId} setIsInvoking={setIsInvoking}>
                <LabHeader
                    buttonProps={commonBtnProps}
                    sessionExpired={GetLab.data.sessionExpired}
                    {..._pick(GetBlueprint.data, [
                        "title",
                        "description",
                        "consoleAccess",
                    ])}
                />
            </SessionQuery>
            <div className="LabMain">
                <Sidebar>
                    <ConsoleSecrets {...GetLab.data.parsedSecrets} />
                    <TableOfContents
                        tocElements={tocElements}
                        activeIndex={activeTocIndex}
                    />
                </Sidebar>
                <LabContent
                    {...{
                        sessionLoading,
                        sessionReady,
                        isInvoking,
                        xhrErrorStatuses,
                        sessionError,
                    }}
                    markdown={GetMarkdown.data.body}
                    setToc={setToc}
                    ref={bodyRef}
                    contentRef={contentRef}
                />
            </div>
        </div>
    );
};

export default LabWrapper;
