import React, { useEffect, useState, Fragment } from "react";
import _get from "lodash/get";

import {
    Timer,
    SessionButton,
    ConsoleButton,
    ExpiredModal,
    ConfirmEndModal,
    MultipleSessionModal
} from "..";
import { useTimer } from "../../utils/hooks/useTimer";
import "./LabHeader.scss";

const LabHeader = ({
    startSession,
    errors,
    endSession,
    consoleUrl,
    consoleUrlLoading,
    buttonProps,
    title,
    description,
    consoleAccess,
    sessionExpired,
    endsOn,
}) => {
    const [isExpired, setIsExpired] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [multipleSessionModalIsVisible, multipleSessionModalIsVisibleSet] = useState(false);
    const [multipleSessionModalConfirmed, multipleSessionModalConfirmedSet] = useState(false);

    const { time, setTime, startTimer, stopTimer } = useTimer(() => {
        // we want to close the end lab confirmation modal
        // and only show the expired modal in case the user
        // was in the process of ending their lab when it expires
        setIsConfirming(false);
        setIsExpired(true);
    });

    const promptConfirmEnd = () => setIsConfirming(true);

    const confirmEnd = () => {
        stopTimer();
        endSession();
    };

    const onMultipleSessionModalClose = () => {
        multipleSessionModalIsVisibleSet(false);
        multipleSessionModalConfirmedSet(true);
    };

    useEffect(() => {
        if (!endsOn) return
        const now = Date.now() / 1000
        setTime(Math.max(0, endsOn - now));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endsOn]);

    useEffect(() => {
        if (!consoleUrl) return;
        startTimer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consoleUrl]);

    useEffect(() => {
        if (sessionExpired) {
            setIsExpired(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionExpired]);

    useEffect(() => {
        if (consoleUrlLoading) {
            multipleSessionModalIsVisibleSet(false);
            multipleSessionModalConfirmedSet(false);
            return;
        }
        if (_get(errors, "startSessionError.status") === 409) {
            multipleSessionModalIsVisibleSet(true);
        }
    }, [errors, consoleUrlLoading, multipleSessionModalIsVisibleSet])

    return (
        <Fragment>
            <div className="Header">
                <div className="HeaderContent">
                    <h2 className="HeaderContent__title" data-testid="class-title">{title}</h2>
                    {description ? (
                        <p className="HeaderContent__details">{description}</p>
                    ) : null}
                </div>
                <div className="ActionBar">
                    {time ? <Timer time={time * 1000} /> : null}
                    <SessionButton
                        invokeStartSession={startSession}
                        confirmEndSessionAction={promptConfirmEnd}
                        isInvoking={consoleUrlLoading}
                        {...buttonProps}
                    />
                    <ConsoleButton
                        consoleAccess={consoleAccess}
                        consoleUrl={consoleUrl}
                        isConsoleUrlLoading={consoleUrlLoading}
                        {...buttonProps}
                    />
                </div>
            </div>
            <ExpiredModal onConfirm={confirmEnd} isExpired={isExpired} />
            <MultipleSessionModal
                visible={multipleSessionModalIsVisible}
                errorConfirmed={multipleSessionModalConfirmed}
                onClose={onMultipleSessionModalClose}
            />
            <ConfirmEndModal
                isConfirming={isConfirming}
                onConfirm={confirmEnd}
                setIsConfirming={setIsConfirming}
            />
        </Fragment>
    );
};

export default LabHeader;
