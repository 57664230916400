import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Icon } from "@amzn/awsui-components-react";
import classnames from "classnames";

import messages from "./Sidebar.messages";
import "./Sidebar.scss";

const Sidebar = ({ children }) => {
    const [isHidden, setSidebarVisibility] = useState(false);
    const { formatMessage } = useIntl();

    const toggleSidebar = () => setSidebarVisibility(!isHidden);

    const toggleMessage = isHidden
        ? messages.showSidebar
        : messages.hideSidebar;

    return (
        <div className={classnames("sidebar", { "is-hidden": isHidden })}>
            <div className="sidebar__header">
                <h2 className="sidebar__header__title">
                    {formatMessage(messages.sidebarTitle)}
                </h2>
                <button
                    className="sidebar__header__toggle"
                    type="button"
                    onClick={toggleSidebar}
                >
                    <Icon name={isHidden ? "menu" : "close"} />
                    <span className="is-visually-hidden">
                        {formatMessage(toggleMessage)}
                    </span>
                </button>
            </div>
            <div className="sidebar__body">{children}</div>
        </div>
    );
};

export default Sidebar;
