import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Alert } from "@amzn/awsui-components-react";

import { ErrorMessage } from "..";
import messages from "./ContentMessage.messages";
import { authorizationError } from "../../utils/helpers";

import "./ContentMessage.scss";

const ContentMessage = ({
    xhrErrorStatuses,
    sessionLoading,
    sessionReady,
    isInvoking,
    sessionError,
}) => {
    const { formatMessage } = useIntl();
    const [visibilityState, setVisibility] = useState({
        loading: true,
        ready: true,
    });
    const onDismiss = name => () =>
        setVisibility(state => ({ ...state, [name]: false }));

    let children;
    if (
        sessionError ||
        (xhrErrorStatuses.length && !xhrErrorStatuses.includes(429))
    ) {
        let type = "fatal";

        if (xhrErrorStatuses.some(authorizationError)) {
            type = "unauthorized";
        } else if (sessionError) {
            type = "sessionError";
        }

        children = <ErrorMessage type={type} />;
    } else if (sessionLoading && !isInvoking) {
        children = (
            <Alert
                visible={visibilityState.loading}
                onDismiss={onDismiss("loading")}
                dismissible
            >
                <p>{formatMessage(messages.sessionProvisioningMessage)}</p>
            </Alert>
        );
    } else if (sessionReady && !isInvoking) {
        children = (
            <Alert
                type="success"
                visible={visibilityState.ready}
                onDismiss={onDismiss("ready")}
                dismissible
            >
                <p data-testid="services-ready">
                    {formatMessage(messages.sessionReadyMessage)}
                </p>
            </Alert>
        );
    }
    return children ? <div className="content-message">{children}</div> : null;
};

export default ContentMessage;
