import { defineMessages } from "react-intl.macro";

export default defineMessages({
    expiredHeadingText: {
        id: "label.end-session-modal.header",
        description:
            "Displayed as the heading of a modal where the user is informed that their session has ended.",
        defaultMessage: "Lab Expiration Notification",
    },
    expiredBodyText: {
        id: "label.end-session-modal.body",
        description:
            "Displayed as the body of a modal where the user is informed that their session has ended.",
        defaultMessage: "Your lab has expired. Close the lab to exit.",
    },
    expiredButtonText: {
        id: "label.end-session-modal.button",
        description:
            "Displayed as the action button text of a modal where the user is informed that their session has ended.",
        defaultMessage: "Close lab",
    },
    confirmHeadingText: {
        id: "label.confirm-end-session-modal.header",
        description:
            "Displayed as the heading of a modal where the user confirms whether to end their session.",
        defaultMessage: "Confirm End Lab",
    },
    confirmBodyText: {
        id: "label.confirm-end-session-modal.body",
        description:
            "Displayed as the body of a modal where the user confirms whether to end their session.",
        defaultMessage:
            "The AWS resources for this lab will be unavailable after you end the lab.",
    },
    endSession: {
        id: "modal.session.end-session",
        description:
            "Session action bar session button text for ending a session",
        defaultMessage: "End lab",
    },
    cancel: {
        id: "label.cancel.text",
        description: "Cancel label",
        defaultMessage: "Cancel",
    },
    multipleSessionTitle: {
        id: "modal.multiple-session.title",
        description: "Title of the multiple session error modal",
        defaultMessage: "You already have an active lab session"
    },
    multipleSessionBody: {
        id: "modal.multiple-session.body",
        description: "Main content of the multiple session error modal",
        defaultMessage: "You can have only one lab session active at a time. Please end your current session to begin another one."
    },
    multipleSessionButton: {
        id: "modal.multiple-session.btn",
        description: "Button label for the multiple session error modal",
        defaultMessage: "Close"
    },
});
