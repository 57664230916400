import { defineMessages } from "react-intl.macro";

export default defineMessages({
    sidebarTitle: {
        id: "sidebar.title",
        description: "Displayed in sidebar header section",
        defaultMessage: "Lab Information",
    },
    showSidebar: {
        id: "sidebar.show",
        description:
            "Displayed as the text of a button to show (reveal into view) the sidebar.",
        defaultMessage: "Show",
    },
    hideSidebar: {
        id: "sidebar.hide",
        description:
            "Displayed as the text of a button to hide (dismiss from view) the sidebar.",
        defaultMessage: "Hide",
    },
});
