import { useState, useEffect } from "react";

export const useTimer = onComplete => {
    const [time, setTime] = useState(0);
    const [isCounting, setIsCounting] = useState(false);
    const [timeoutId, setTimeoutId] = useState(0);

    const decrement = () => {
        setTimeoutId(
            setTimeout(() => {
                setTime(time - 1);
            }, 1000)
        );
    };

    const startTimer = () => {
        setIsCounting(true);
    };

    const stopTimer = () => {
        clearTimeout(timeoutId);
        setIsCounting(false);
    };

    useEffect(() => {
        if (!isCounting) return;
        if (time <= 0) {
            setIsCounting(false);
            onComplete();
        } else {
            clearTimeout(timeoutId);
            decrement();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time, isCounting]);

    useEffect(() => () => clearTimeout(timeoutId), [timeoutId]);

    return {
        time,
        setTime,
        startTimer,
        stopTimer,
    };
};
