export const JS_ERROR_TYPE = "Error";
export const ERROR_PAGE_TYPE = "ErrorPage";
export const AUTH_ERROR_TYPE = "AuthError";
export const APP_ERROR_NAME = "AppError";
export const FETCH_ERROR_NAME = "FetchError";

export class AppError extends Error {
    constructor(message, params = {}) {
        super(message.replace(/Error:\s/, ""));

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AppError);
        }

        this.type = params.type || JS_ERROR_TYPE;
        this.name = params.name || APP_ERROR_NAME;
        this.status = params.status;
    }
}
