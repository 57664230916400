import { defineMessages } from "react-intl.macro";

export default defineMessages({
    startSession: {
        id: "action.session.start-session",
        description:
            "Session action bar session button text for starting a session",
        defaultMessage: "Start lab",
    },
    endSession: {
        id: "action.session.end-session",
        description:
            "Session action bar session button text for ending a session",
        defaultMessage: "End lab",
    },
    openConsole: {
        id: "action.session.open-console",
        description: "Session action bar button text for opening the console",
        defaultMessage: "Open Console",
    },
    loading: {
        id: "label.loading.title",
        description: "Generic loading title",
        defaultMessage: "Loading",
    },
});
