/**
 * Stripped Content.js from Session UI.
 */

import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import MarkdownIt from "markdown-it";

import sanitizeHtml from "../utils/sanitize.utils";
import highlight from "../utils/highlight.utils";
import { rendererPlugins, usePlugins } from "../plugins/index";

import { performanceTracker } from "../../../utils/performance";
import { useComponentWillMount } from "../../../utils/hooks";

import "./MarkdownContent.scss";

/**
 * The default settings for markdown.   They enable most of the options to allow the author
 * as much flexibility as possible.
 *
 * @typedef {Object} MarkdownSetting
 * @property {boolean} [html=false] - Allows HTML tags in the source
 * @property {boolean} [xhtmlOut=false] - Use '/' to close single tags br />
 * @property {boolean} [breaks=false] - Changes all \n to break tags
 * @property {boolean} [linkify=false] - Autoconvert URL-like text to links
 * @property {boolean} [typographer=false] - Enable some language-neutral replacement + quotes beautification
 * @property {string|[string]} [quotes='“”‘’'] - Double + single quotes replacement pairs, when typographer enabled and
 *                                               smartquotes on
 */
const defaultSettings = {
    // ../plugins/SanitizeHTML sanitizes the input to allow a whitelist of tags
    html: true,
    xhtmlOut: true,
    breaks: false,
    linkify: false,
    typographer: true,
    highlight: highlight,
};

/**
 * Language specific settings to adjust the smartquotes to the correct unicode
 *
 * @type {[key: string]: MarkdownSetting}
 */
const localeSettings = {
    "ru-RU": {
        ...defaultSettings,
        quotes: "«»„“",
    },
    "de-DE": {
        ...defaultSettings,
        quotes: "„“‚‘",
    },
    "fr-FR": {
        ...defaultSettings,
        quotes: ["«\xA0", "\xA0»", "‹\xA0", "\xA0›"],
    },
    "ko-KR": {
        ...defaultSettings,
        quotes: "『』「」",
    },
    "ja-JP": {
        ...defaultSettings,
        quotes: "「」『』",
    },
    "pt-BR": {
        ...defaultSettings,
        quotes: "“”‘’",
    },
    "zh-Hans-CN": {
        ...defaultSettings,
        quotes: "“”‘’",
    },
    "zh-Hant-TW": {
        ...defaultSettings,
        quotes: "「」『』",
    },
    default: defaultSettings,
};

/**
 * Create a new MarkdownIt instance using the provided settings and adding additional plugins.
 *
 * @param {MarkdownSetting} settings The settings that the instance should be created with
 * @param {func} setToc Method to set the table of contents array
 * @returns {MarkdownIt} The instance that can be used to render markdown
 */
const createInstance = (settings, setToc) => {
    const instance = new MarkdownIt({ ...settings, ...defaultSettings });

    rendererPlugins.forEach(plugin => plugin(instance.renderer));

    usePlugins.forEach(plugin => {
        const pluginAndOptions = plugin(setToc);
        instance.use(pluginAndOptions.plugin, pluginAndOptions.options);
    });

    return instance;
};

/**
 * React Component for displaying markdown.  Requires markdown
 *
 * @note Does not output a React tree but HTML as a string and so must be set directly into the DIV.
 *
 * @param {string} markdown The markdown that you want to render
 * @param {function} setToc Callback function to update table of content
 * @constructor
 */
const MarkdownContent = React.forwardRef(({ setToc, markdown }, ref) => {
    const { locale } = useIntl();
    const [sanitizedHtml, setSanitizedHtml] = useState();
    useComponentWillMount(performanceTracker.markMarkupRender.start);

    useEffect(() => {
        const settings = localeSettings[locale] || localeSettings.default;
        performanceTracker.markMarkdownConvert.start();
        const origHtml = createInstance(settings, setToc).render(markdown);
        performanceTracker.markMarkdownConvert.end();
        setSanitizedHtml(sanitizeHtml(origHtml));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale, markdown]);

    useEffect(() => {
        if (!sanitizedHtml) return;
        performanceTracker.markMarkupRender.end();
        performanceTracker.markAppReady.end();

        performanceTracker.recordAppReady();
    }, [sanitizedHtml]);

    return (
        <div
            ref={ref}
            className="labs-markdown-content"
            data-testid="markdown-content"
            dangerouslySetInnerHTML={{
                __html: sanitizedHtml,
            }}
        />
    );
});

export default MarkdownContent;
