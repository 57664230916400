import { withStatusAndMessage } from "./withStatusAndMessage";

const className = "console-button";

const consoleButtonSessionLoading = () =>
    withStatusAndMessage("consoleLoading")({ loading: true, className });

const consoleButtonSessionReady = () =>
    withStatusAndMessage("consoleReady")({
        className,
        icon: "external",
        disabled: true,
    });

const consoleButtonSessionActive = consoleUrl =>
    withStatusAndMessage("consoleRunning")({
        className,
        href: consoleUrl,
        target: "_blank",
        icon: "external",
    });

const consoleButtonSessionFinished = () =>
    withStatusAndMessage("consoleFinished")({
        className,
        icon: "external",
        disabled: true,
    });

export const ConsoleButton = ({
    consoleAccess,
    consoleUrl,
    isConsoleUrlLoading,
    sessionReady,
    sessionLoading,
    sessionActive,
    sessionEnded,
}) => {
    if (consoleAccess) {
        if (sessionLoading || isConsoleUrlLoading) {
            return consoleButtonSessionLoading();
        } else if (sessionReady) {
            return consoleButtonSessionReady();
        } else if (sessionActive && consoleUrl) {
            return consoleButtonSessionActive(consoleUrl);
        } else if (sessionActive && !consoleUrl) {
            return consoleButtonSessionReady();
        } else if (sessionEnded) {
            return consoleButtonSessionFinished();
        }
    }
    return null;
};
