import { UserManager } from "oidc-client";
import environment from "../.config/environment";

export const createVibeOidcUserManager = globals => {
    const {
        authorizer: { issuer, client_id },
    } = environment(globals);
    const manager = new UserManager({
        client_id,
        authority: issuer,
        response_type: "code",
        redirect_uri: `${globals.location.origin}/callback`,
    });
    manager.startSilentRenew();
    return manager;
};
