import messages from "./SessionActionsBar.messages";

export const statusAndMessageMap = {
    sessionLoading: {
        modifier: "loading",
        message: messages.startSession,
    },
    sessionReady: {
        modifier: "ready",
        message: messages.startSession,
    },
    sessionRunning: {
        modifier: "running",
        message: messages.endSession,
    },
    sessionFinished: {
        modifier: "finished",
        message: messages.endSession,
    },
    consoleLoading: {
        modifier: "loading",
        message: messages.loading,
    },
    consoleReady: {
        modifier: "ready",
        message: messages.openConsole,
    },
    consoleRunning: {
        modifier: "running",
        message: messages.openConsole,
    },
    consoleFinished: {
        modifier: "finished",
        message: messages.openConsole,
    },
};
