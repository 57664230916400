import { useState, useEffect } from "react";
import _throttle from "lodash/throttle";
import _debounce from "lodash/debounce";

export const getHighestExceededIndex = (arr, num) =>
    arr.reduce((acc, trigger, i) => (num < trigger ? acc : i), 0);

export const getTops = (globals, { bodyHeight, ids }) => {
    const headerHeight = globals.window.innerHeight - bodyHeight;
    return ids.map(
        anchor =>
            globals.document.getElementById(anchor).offsetTop - headerHeight
    );
};

export const convertTopsToTriggers = (tops, { scrollEnd, bodyHeight }) => {
    const halfBodyHeight = bodyHeight / 2;
    return tops.reduce((acc, top, i) => {
        const nextTrigger = tops[i + 1] || scrollEnd + bodyHeight;
        const height = nextTrigger - top;
        if (top < halfBodyHeight) {
            // set trigger to prev trigger + prev height
            const prevTrigger = tops[i - 1];
            const prevHeight = top - prevTrigger;
            return [...acc, !prevTrigger ? 0 : acc[i - 1] + prevHeight];

            // if section isn't tall enough to reach the middle
        } else if (height < halfBodyHeight) {
            // this calculates it the trigger to be the bottom
            // of the section at the bottom of the window
            return [...acc, top - bodyHeight + height];
        }
        // set trigger to middle
        return [...acc, top - halfBodyHeight];
    }, []);
};

export const useActiveIndexScroller = (ids, bodyRef, contentRef) => {
    const [activeTocIndex, setActiveTocIndex] = useState(0);
    const [triggers, setTriggers] = useState([]);

    const onScroll = e => {
        // find the last section index that has exceeded the trigger point
        const currentIndex = getHighestExceededIndex(
            triggers,
            e.target.scrollTop
        );
        setActiveTocIndex(currentIndex);
    };

    const onResize = () => measureTriggers();

    const measureTriggers = () => {
        const bodyHeight = bodyRef.current.offsetHeight;

        const tops = getTops({ window, document }, { bodyHeight, ids });

        const scrollEnd = contentRef.current.offsetHeight - bodyHeight - 20; // 20 is the amount of px padding at the bottom

        const sectionTriggers = convertTopsToTriggers(tops, {
            scrollEnd,
            bodyHeight,
        });
        setTriggers(sectionTriggers);
    };

    useEffect(() => {
        if (!bodyRef.current || !contentRef.current) {
            return;
        }
        const scrollHandler = _throttle(onScroll, 300);
        const resizeHandler = _debounce(onResize, 300);
        const body = bodyRef.current;
        body.addEventListener("scroll", scrollHandler);
        window.addEventListener("resize", resizeHandler);
        return () => {
            body.removeEventListener("scroll", scrollHandler);
            window.removeEventListener("resize", resizeHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggers, bodyRef, contentRef]);

    useEffect(() => {
        if (!ids.length || !bodyRef.current) return;

        // need to watch the content and measure after changes
        const observer = new MutationObserver(measureTriggers);
        observer.observe(bodyRef.current, {
            childList: true,
            subtree: true,
            attributes: true,
        });

        return () => observer.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ids, bodyRef]);

    return activeTocIndex;
};
