import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import _get from "lodash/get";

import { UserContext } from "../Auth";
import { closeTab, isEmpty } from "../../utils/helpers";
import { useQuery } from "../../utils/hooks";
import { performanceTracker } from "../../utils/performance";
import { parseLabData, parseMarkdownData } from "./parsers";

const Query = ({
    params,
    name,
    children,
    dataParser,
    retryPredicate,
    tapResponse,
    skipAuth,
    ...rest
}) => {
    const { loading, data, error, fetch } = useQuery(name);
    const { id_token: idToken } = React.useContext(UserContext);

    useEffect(() => {
        if (error || !idToken) return;

        if (isEmpty(data) && params && !loading) {
            fetch({ params, dataParser, skipAuth, idToken }).then(tapResponse);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, params, loading, error, idToken]);

    useEffect(() => {
        if (
            !retryPredicate ||
            !params ||
            loading ||
            isEmpty(data) ||
            !idToken
        ) {
            return;
        }

        if (retryPredicate({ loading, data, error })) {
            fetch({ params, dataParser, skipAuth, idToken, delay: 10000 }).then(
                tapResponse
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, params, loading, error, idToken]);

    const forwardedProps = { ...rest, [name]: { loading, error, data } };

    return children.props
        ? React.cloneElement(children, forwardedProps)
        : children(forwardedProps);
};

export const GetLabQuery = ({ labId, children, ...rest }) => {
    const params = labId
        ? {
              path: `/${labId}`,
          }
        : undefined;

    return (
        <Query
            params={params}
            name="GetLab"
            dataParser={parseLabData}
            {...rest}
        >
            {children}
        </Query>
    );
};

export const GetBlueprintQuery = ({ children, labId, ...rest }) => {
    const { locale } = useIntl();
    const params = labId
        ? {
              path: `/${locale}/lab/${labId}`,
          }
        : undefined;

    return (
        <Query params={params} name="GetBlueprint" {...rest}>
            {children}
        </Query>
    );
};

export const GetMarkdownQuery = ({ children, ...rest }) => {
    const markdownUrl = _get(rest, "GetBlueprint.data.markdown.url");
    const params = markdownUrl
        ? {
              path: markdownUrl,
          }
        : undefined;

    if (params) {
        performanceTracker.markMarkdownRequest.start();
    }

    return (
        <Query
            params={params}
            name="GetMarkdown"
            dataParser={parseMarkdownData}
            skipAuth
            {...rest}
        >
            {children}
        </Query>
    );
};

export const SessionQuery = ({
    children,
    labId,
    setIsInvoking
}) => {
    const {
        data: startSessionData,
        error: startSessionError,
        fetch: startSessionFetch,
        loading: startSessionLoading
    } = useQuery("StartSession");
    const {
        data: getSessionData,
        error: getSessionError,
        fetch: getSessionFetch,
    } = useQuery("GetSession");
    const {
        data: getLabData,
        error: getLabError,
        fetch: getLabFetch,
    } = useQuery("GetLab");
    const {
        loading: cancelLabLoading,
        error: cancelLabError,
        fetch: cancelLabFetch,
    } = useQuery("CancelLab");
    const { id_token: idToken } = React.useContext(UserContext);
    const [cancelConfirmed, setCancelConfirmed] = useState(false);
    const [sessionAttempt, setSessionAttempt] = useState(0);
    const labAttempt = useRef(0);

    const startSession = () => {
        setIsInvoking(true);
        startSessionFetch({
            idToken,
            params: { path: `/${labId}`, method: "POST" },
        });
        performanceTracker.markStartLabToConsole.start();
    };

    const endSession = () => {
        cancelLabFetch({ idToken, params: { path: `/${labId}` } });
    };

    const sessionData = Object.assign({}, startSessionData, getSessionData);

    const props = {
        ...sessionData,
        startSession,
        endSession,
        consoleUrlLoading:
            // if we're waiting for startSessionData to return
            // OR
            // there is startSessionData and combined session data doesn't have the consoleUrl
            startSessionLoading ||
            (!isEmpty(startSessionData) && !sessionData.consoleUrl),
        errors: {
            startSessionError,
            getSessionError,
            getLabError,
            cancelLabError,
        },
    };

    // need to get the session data if the lab is already 'RUNNING'
    useEffect(() => {
        if (isEmpty(startSessionData) && getLabData.sessionActive) {
            getSessionFetch({ idToken, params: { path: `/${labId}` } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getLabData, startSessionData, labId]);

    // handles getting the console url from GetSession until returned
    useEffect(() => {
        if (isEmpty(startSessionData)) return;
        if (startSessionData.consoleUrl || getSessionData.consoleUrl) {
            setIsInvoking(false);
            performanceTracker.markStartLabToConsole.end();
            performanceTracker.recordStartLabToConsole();
            return;
        }
        const delay = sessionAttempt === 0 ? 0 : 3000;

        const id = setTimeout(() => {
            setSessionAttempt(attempt => attempt + 1);
            getSessionFetch({ idToken, params: { path: `/${labId}` } });
        }, delay);

        return () => clearTimeout(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startSessionData, getSessionData]);

    // handles getting the latest lab status until it changes from 'READY' after starting a session
    useEffect(() => {
        if (isEmpty(startSessionData)) return;
        if (!getLabData.sessionReady) return;
        const delay = labAttempt.current === 0 ? 0 : 3000;

        const id = setTimeout(() => {
            labAttempt.current++;
            getLabFetch({
                idToken,
                params: { path: `/${labId}` },
                dataParser: parseLabData,
            });
        }, delay);

        return () => clearTimeout(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startSessionData, getLabData]);

    // checks that the cancel lab call has been made
    // TODO: will need to handle for when lab times out vs user initiated action
    useEffect(() => {
        if (cancelLabLoading) {
            setCancelConfirmed(true);
        } else if (!cancelLabLoading && cancelConfirmed) {
            closeTab(window);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancelLabLoading]);

    return React.cloneElement(children, props);
};
