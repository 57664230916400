import React from "react";
import { Button, Popover, Icon } from "@amzn/awsui-components-react";
import { copyToClipboard } from "../../../utils/helpers";

import "./CodeBlock.scss";

const CodeBlock = ({ children, content }) => {
    return (
        <div className="labs-code-block">
            <div className="labs-box">
                <div>
                    <span className="awsui-util-copy-text">
                        <Popover
                            size="small"
                            position="right"
                            triggerType="custom"
                            dismissButton={false}
                            content={
                                <span className="awsui-util-status-positive">
                                    <Icon
                                        variant="success"
                                        name="status-positive"
                                    />{" "}
                                    Copied
                                </span>
                            }
                        >
                            <Button
                                icon="copy"
                                variant="icon"
                                label="Copy"
                                onClick={() =>
                                    copyToClipboard({
                                        globals: window,
                                        value: content,
                                    })
                                }
                            />
                        </Popover>
                    </span>
                </div>
                <div className="labs-box-inner">{children}</div>
            </div>
        </div>
    );
};

export default CodeBlock;
