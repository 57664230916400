import { useRef } from "react";
import { useLocation } from "react-router-dom";

export const useHashScrollOnLoad = (globals) => {
    const { hash } = useLocation();
    const decodedHash = decodeURIComponent(hash)
    const hasScrolled = useRef(false);
    if (decodedHash.length && globals.document.querySelector(decodedHash) && !hasScrolled.current) {
        hasScrolled.current = true;
        setTimeout(() => {
            globals.location.hash = "";
            globals.document.querySelector(`a[href="${hash}"]`).click();
        }, 300);
    }
};
