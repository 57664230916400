import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Analytics from "@aws-amplify/analytics";
import { statlerProviderName } from "../../utils/Analytics";
import { AUTH_ERROR_TYPE } from "../../utils/errors";

/**
 * @type {React.Context<import("oidc-client").UserManager | null>}
 */
export const UserManagerContext = React.createContext(null);

/**
 * Provides the authenticated user data
 * @type {React.Context<import("oidc-client").UserManager | null>}
 */
export const UserContext = React.createContext(null);

/**
 *
 * @param { Promise<T>} promise
 * @param {(err: unknown) => void} [onError]
 * @returns {[Promise<T>, () => void]}
 * @template T
 */
export const withCancellation = promise => {
    let cancelled = false;
    return [
        new Promise((resolve, reject) => {
            promise
                .then(result => {
                    if (!cancelled) {
                        return resolve(result);
                    }
                })
                .catch(err => {
                    if (!cancelled) {
                        return reject(err);
                    }
                });
        }),
        () => {
            cancelled = true;
        },
    ];
};

/**
 * @param {*} [state] optional state to preserve across redirect.
 * @returns {void}
 */
export function useSigninRedirect() {
    const userManager = React.useContext(UserManagerContext);
    const location = useLocation();
    React.useEffect(() => {
        userManager.signinRedirect({
            state: {
                pathname: location.pathname,
                search: location.search,
            },
        });
    });
}

export function RedirectToSignin() {
    useSigninRedirect();
    return null;
}

export function Authenticated({
    fallback = <RedirectToSignin />,
    loading = null,
    children,
}) {
    const userManager = React.useContext(UserManagerContext);
    const [state, setState] = React.useState({
        data: null,
        error: null,
        loading: true,
    });

    React.useEffect(() => {
        const [p, cancel] = withCancellation(userManager.getUser());
        p.then(user =>
            setState({ data: user, loading: false, error: null })
        ).catch(err => setState({ data: null, loading: false, error: err }));
        return cancel;
    }, [userManager]);

    if (state.loading) return loading;

    if (state.error || !state.data || state.data.expired) {
        return fallback;
    }

    return (
        <UserContext.Provider value={state.data}>
            {children}
        </UserContext.Provider>
    );
}

/**
 * @returns {void}
 */
export function useSigninRedirectCallback() {
    const userManager = React.useContext(UserManagerContext);
    const history = useHistory();
    React.useEffect(() => {
        const [p, cancel] = withCancellation(
            userManager.signinRedirectCallback()
        );
        p.then(user => {
            const { pathname = "/", search = "" } = user.state || {};
            history.push(`${pathname}${search}`);
        }).catch(err => {
            Analytics.record(
                {
                    type: AUTH_ERROR_TYPE,
                    payload: {
                        ...err,
                        url: err.url || "",
                        userAgent:
                            window.navigator && window.navigator.userAgent,
                    },
                },
                statlerProviderName
            );
        });
        return cancel;
    });
}

export function Callback() {
    useSigninRedirectCallback();
    return null;
}
