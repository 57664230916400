import React from "react";
import classNames from "classnames";

import { Loader, ContentMessage } from "..";
import MarkdownContent from "../Markdown/components/MarkdownContent";
import "./LabContent.scss";
import { performanceTracker } from "../../utils/performance";

const LabContent = React.forwardRef((props, ref) => {
    const isLoading = !props.markdown;
    if (!isLoading) {
        performanceTracker.markLoadingScreen.end();
        performanceTracker.markMarkdownRequest.end();
    }

    return (
        <div
            className={classNames("lab-content", { "is-loading": isLoading })}
            ref={ref}
        >
            <div className="lab-content__inner">
                <ContentMessage
                    sessionLoading={props.sessionLoading}
                    sessionReady={props.sessionReady}
                    isInvoking={props.isInvoking}
                    xhrErrorStatuses={props.xhrErrorStatuses}
                    sessionError={props.sessionError}
                />
                <Loader
                    visible={isLoading}
                    hasError={props.xhrErrorStatuses.length}
                >
                    <MarkdownContent {...props} ref={props.contentRef} />
                </Loader>
            </div>
        </div>
    );
});

export default LabContent;
