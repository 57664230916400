import { defineMessages } from "react-intl.macro";

export default defineMessages({
    sessionProvisioningMessage: {
        id: "status.provisioning.app-message",
        description:
            "Displayed near top of page when user must wait until the lab is ready to be started",
        defaultMessage: "AWS Services are loading.",
    },
    sessionReadyMessage: {
        id: "status.ready.app-message",
        description:
            "Displayed near top of page when user is able to start the lab",
        defaultMessage: "Your AWS Services are ready.",
    },
});
