import React from "react";
import { useIntl } from "react-intl";
import { Button } from "@amzn/awsui-components-react";
import classnames from "classnames";

import { statusAndMessageMap } from "./constants";
import "./SessionActionsBar.scss";

export const withStatusAndMessage = status =>
    function Wrapper({ className, ...props }) {
        const { formatMessage } = useIntl();
        const { modifier, message } = statusAndMessageMap[status];
        return (
            <Button
                className={classnames(className, `${className}--${modifier}`)}
                {...props}
            >
                {formatMessage(message)}
            </Button>
        );
    };
