import React, { Fragment } from "react";
import { render } from "react-dom";
import _isMatch from "lodash/isMatch";

/**
 * Set the value(s) of one or more named attributes in a token. The value is replaced
 * if it was already specified, otherwise a name/value pair added.
 *
 * @param <{ attrIndex, attrPush, attrs }> token    Token from Markdown-it parser
 * @param <Object> attrs                            Map of attribute names to values
 */
export const setAttrs = (token, attrs) => {
    Object.keys(attrs).forEach(attrKey => {
        const attrValue = attrs[attrKey];
        const aIndex = token.attrIndex(attrKey);
        if (aIndex < 0) {
            // add new attribute
            token.attrPush([attrKey, attrValue]);
        } else {
            // replace value of existing attr
            token.attrs[aIndex][1] = attrValue;
        }
    });
};

/**
 * Override a rule in the markdown-it renderer. Refer to
 * https://github.com/markdown-it/markdown-it/blob/master/lib/renderer.js for context.
 *
 * @param {Renderer} renderer    Field 'renderer' of an instance of a MarkdownIt object.
 *
 * @param {string} type          Name of rule to update. eg. 'fence', 'paragraph-open', etc...
 *                               The "debug" tab of https://markdown-it.github.io/ is useful to
 *                               determine which rules you want to overwrite.
 *
 * @param {function({ origRender, tokens, options, env, self })} ruleFn
 *     function that returns a string of HTML to render. Function accepts an object with fields:
 *
 *     1) origRender {function()} a function that returns the string result of the previous override
 *        of the rule (or the markdown-it rendering if not overridden)
 *
 *     2) tokens {Token[]}, idx {int}, options {Object}, env {Object}, and self {Renderer} from the
 *        signature of a markdown-it rule function.
 *
 * Examples:
 *
 * Function that takes a renderer and adds attribute target=_blank to all links:
 *
 * (renderer) => setRenderingRule(renderer, 'link_open', ({ origRender, tokens, idx }) => {
 *     const aIndex = tokens[idx].attrIndex('target');
 *     if (aIndex < 0) {
 *         tokens[idx].attrPush(['target', '_blank']); // add new attribute
 *     } else {
 *         tokens[idx].attrs[aIndex][1] = '_blank'; // replace value of existing attr
 *     }
 *     return origRender();
 * })
 *
 * Functions that take a renderer and decorate inline <strong> elements with a compliment:
 *
 * [(renderer) => setRenderingRule(renderer, 'strong_open', ({ origRender }) => {
 *     return `<span>Aren't you strong! ${origRender()}`;
 * }),
 * (renderer) => setRenderingRule(renderer, 'strong_close', ({ origRender }) => {
 *     return `${origRender()}</span>`;
 * })]
 */
export const renderingRule = (type, ruleFn) => renderer => {
    const origRule =
        renderer.rules[type] ||
        ((tokens, idx, options, env, self) =>
            self.renderToken(tokens, idx, options));

    renderer.rules[type] = (tokens, idx, options, env, self) =>
        ruleFn({
            origRender: () => origRule(tokens, idx, options, env, self),
            tokens,
            idx,
            options,
            env,
            self,
        });
};

/**
 * Use as "ruleFn" param of setRenderingRule() to conditionally render with a custom
 * rule function if the current and following tokens match a specified list of objects.
 * If the token stream does not match, the custom rule function is not invoked and
 * the token is rendered with whatever custom or default rules are in place.
 *
 * @param {token partial object or array of token partial objects} tokenPartials
 *     Object(s) that the current and following tokens will be compared against
 *     to determine whether the ruleFn should be used. Examples:
 *
 *     {}: match all tokens
 *     {type: 'text'} match tokens of type 'text'
 *     etc...
 *
 *     If argument is an array, the current token is matched against the first element,
 *     the next token is matched against the second element, etc... If argument is not
 *     array, the current token is matched against the argument.
 *
 * @param {function({ origRender, tokens, idx, options, env, self })} ruleFn
 *     Same description/purpose as in setRenderingRule() above.
 */
export const renderIfTokensMatch = (tokenPartials, ruleFn) => renderArgs => {
    if (tokenPartials.length === undefined) {
        tokenPartials = [tokenPartials];
    }
    if (
        renderArgs.idx + tokenPartials.length - 1 < renderArgs.tokens.length &&
        tokenPartials.every((tokenData, tokenDataIdx) =>
            _isMatch(renderArgs.tokens[renderArgs.idx + tokenDataIdx], tokenData)
        )
    ) {
        return ruleFn(renderArgs);
    }
    return renderArgs.origRender();
};

/**
 * Use as "ruleFn" param of setRenderingRule() to render the element the way it originally
 * would have been with additional attributes.
 *
 * @param {Object} attrs    Additional attributes as key-value pairs.
 *
 * eg. renderingRule('ordered-list-item-open', renderWithAttrs({ class: 'class-for-list-items' }))
 */
export const renderWithAttrs = attrs => ({ origRender, tokens, idx }) => {
    setAttrs(tokens[idx], attrs);
    return origRender();
};

/**
 * Use as "ruleFn" param of setRenderingRule() to render a React component in place of the HTML element
 * that markdown-it would have rendered.
 *
 * @param {function({ origContents, elementId, content })} genReactFn
 *     function (params) that returns a JSX element to render. Your element will be wrapped in Provider
 *     and IntlProvider so that it has access to the Redux store and i18n facilities respectively.
 *     Function accepts an object with fields:
 *
 *     1) origContents {function ()}, a function that returns a JSX representation of the the string
 *        result of the previous override of the rule (or the markdown-it rendering if not overridden)
 *
 *     2) elementId {string}, an identifier of a placeholder element that will enclose your JSX. Not
 *        always needed, but useful if you need to select elements within your component
 *
 *     3) content {object}, the original content
 *
 *
 * @param {{ globals: { document: Document }}} options
 *     For unit testing. Leave unspecified or pass { globals: window }
 *
 *
 * For an example, see ../plugins/RenderCodeBlock.js or ../plugins/RenderInlineCode.js
 */
export const renderReact = (genReactFn, { globals = window } = {}) => ({
    origRender,
    env,
    tokens,
    idx,
    content = tokens[idx],
}) => {
    if (env.reactComponentIdx === undefined) {
        env.reactComponentIdx = 0;
    }

    const elementId = ["amazonaws", "labs", "rc", env.reactComponentIdx++].join(
        "-"
    );
    const origContents = () => (
        <div dangerouslySetInnerHTML={{ __html: origRender() }}></div>
    );

    setTimeout(() => {
        render(
            <Fragment>
                {genReactFn({ origContents, elementId, content })}
            </Fragment>,
            globals.document.getElementById(elementId)
        );
    });

    return `<span id="${elementId}"></span>`;
};
